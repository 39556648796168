/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { FieldContainer } from '../EventDetails/styles';
import { LayoutNew, CommentContentContainer, ChallengeGrid, ChallengeInfoGridNew, CommentGridNew, ChatGroup, ChatUsers, ChatTextArea, ChatCommentContent, IconContainer, InputImage, StyledTabsNew, Main, EventsButton, ButtonContainer, IconContainerNew, InputImageNew, CompletedChallengeCard } from './styles';
import { NoComments } from '../SocialFeeds/styles';
import CommentsV2 from '../CommentsV2';
import BehaviorChallengeComments from '../CommentsV2/behaviorChallengeComments'
import Waiting from '../Waiting';
import DetailedChallengeV2 from './DetailedChallengeV2'
import GroupChallengeLeaderboardV2 from './GroupTeamGoal/newGroupChallenge';
import Loading from '../Loading';
import GroupOverViewScreen from './OverViewScreen/groupOverview'
import MyTaskTabV2 from "./MyTaskTab/myTaskTab";
const StartChallengePopUp = React.lazy(() => import("./StartChallengePopUp"));
import {
  getChallengeDetails, addCommentOnChallenge, getCommentsOnChallenge, getChallengeCompetitors,
  addCommentReplyOnChallenge, getUserProfileImage, getUserId, getTeamChallenge, updateDailyTask, changeWeekDashboard, getUserListOfChallenges, getUserLastSync, getUserOverviewDetails, getChallengeCsvData, emptyChallengeDetails, toggleDummyChallenges, setChallengeInviteStatus,
  joinOrLeaveChallenge
} from '../../redux/actions';
import Image from '../Image';
import { Tab } from "react-bootstrap";
import MyProgressTab from "./MyProgressTab/myProgressTabV2";
import { ProfileImage } from './OverViewScreen/styles';
import { withRouter } from "react-router-dom";
import moment from "moment";
import { resetOrientation, getOrientation, checkImage, createSafeHTML } from '../../utils/methods';
import { imageErrorMessage } from '../../utils/constants';
import BehaviorChallengeDetail from "./BehaviorChallengeNew";
const DemoPopup = React.lazy(() => import('../WhyAmigoCommon/ChallengePopup'));
import RightSideSection from '../ChallengeDetailsV2/MyTaskTab/behaviorChallengeRightSide'
import { AboutContainer, CommentContent, PostButton, FormContainer } from '../EventDetails/styles';
import { ImageWrapper } from '../SocialFeeds/styles';
import { RightContainer } from './MyTaskTab/styles';
import GroupRightSection from './GroupTeamGoal/groupRightSection';
import ClassicRightSection from './GroupTeamGoal/classicChallengeRight';
import ClassicChallengeLeaderboard from './GroupTeamGoal/classicLeaderboard';
import SurvivorRightSection from './survivorChallengeRight';
import SurvivorOverViewScreen from './OverViewScreen/survivorOverview';
import SurvivorLeaderboard from './ChallengeLeaderboardV2/survivorLeaderboard';
import { withTranslation } from 'react-i18next';
import CommonTextArea from '../common/CommonTextArea/CommonTextArea';

class AdminCompletedChallengeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      isUpdatedImage: false,
      selectedTab: 1,
      objIndex: 0,
      taskUpdated: 0,
      selectedDate: moment(moment()._d, 'MM DD YYYY').format('MM-DD-YYYY'),
      refreshFileReader: false,
      imgSrc: [],
      showVideo: false,
      joinOrLeave: 1,
      isButtonDisabled: false
    }
  }

  componentDidMount() {
    const { location: { pathname, search }, getChallengeDetails, getCommentsOnChallenge, fetchUserProfileImage, fetchUserId, match, getUserListOfChallenges } = this.props;
    const StringArray = pathname.split('/');
    if (StringArray[(StringArray.length - 1)] != '') {
      this.props.toggleDummyChallenges(search.includes('ACME=true'));
      getChallengeDetails(parseInt(match.params.id,10));
      getCommentsOnChallenge(parseInt(match.params.id,10));
      getUserListOfChallenges(parseInt(match.params.id,10));
    } else {
      getChallengeDetails(StringArray[(StringArray.length - 2)]);
      getCommentsOnChallenge(StringArray[(StringArray.length - 2)]);
    }
    if (!this.props.syncTime) {
      this.props.getUserLastSync();
    }
    fetchUserId();
    if (!this.props.profileImage) fetchUserProfileImage();
    this.checkBase64(this.props.profileImage);
  }
  
  componentDidUpdate(prevProps) {
    const { location: { pathname, search }, getChallengeDetails, getCommentsOnChallenge, match, getUserListOfChallenges } = this.props;
    const StringArray = pathname.split('/');
    if (pathname !== prevProps.location.pathname) {
      if (StringArray[(StringArray.length - 1)] != '') {
        this.props.toggleDummyChallenges(search.includes('ACME=true'));
        getChallengeDetails(parseInt(match.params.id,10));
        getCommentsOnChallenge(parseInt(match.params.id,10));
        getUserListOfChallenges(parseInt(match.params.id,10));
      } else {
        getChallengeDetails(StringArray[(StringArray.length - 2)]);
        getCommentsOnChallenge(StringArray[(StringArray.length - 2)]);
      }
    }
  }

  navigateEdit = () => {
    const { location: { pathname }, history } = this.props;
    const StringArray = pathname.split('/');
    if (StringArray[StringArray.length - 1] != '') {
      history.push(`/challenges/${StringArray[StringArray.length - 1]}/edit`);
    } else {
      history.push(`/challenges/${StringArray[StringArray.length - 2]}/edit`);
    }
  }

  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          isUpdatedImage: true
        })
      }
      else {
        this.setState({
          isUpdatedImage: false
        })
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage)
    }
  }
  componentWillUnmount() {
    this.props.emptyChallengeDetails();
    this.props.toggleDummyChallenges(false);
  }

  showProfileImage = () => {
    const { profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if (isUpdatedImage) {
      return (<img src={profileImage} alt="avatar" />)
    }
    else {
      return (<Image image={profileImage} alt="avatar" />)
    }
  };

  onChange = (e) => {
    e.target.value !== ' ' ?
      this.setState({
        comment: e.target.value
      }) : null;
  };

  onChangeImage = (e) => {
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      if ((file?.size / 1000000) <= 20) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState({
            imgSrc: [reader.result],
            refreshFileReader: true
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                imgSrc: [baseImage],
                refreshFileReader: false
              });
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById('uploadPhoto').value = '';
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('uploadPhoto').value = '';
    }
  };

  removeImage = () => {
    this.setState({
      imgSrc: []
    });
  };

  postComment = (id) => {
    const { addCommentOnChallenge } = this.props;
    const { comment, imgSrc } = this.state;
    addCommentOnChallenge({ challenge_id: id, comment: comment, image: imgSrc.length >= 1 ? imgSrc[0] : '' });
    this.setState({
      comment: '',
      imgSrc: []
    })
  };
  setActiveTab = (active) => {
    this.setState({ selectedTab: Number(active) })
  }
  getListOfUser = () => {
    const { challengeAttendees } = this.props;
    return <div>
      {
        challengeAttendees && challengeAttendees.length ? challengeAttendees.map((user, index) => (
          (index < 3) ? <ProfileImage chatHeader={1} key={index}>
            <Image image={user.profile_image} alt="avatar" />
          </ProfileImage> : null
        )
        ) : null
      }
      {
        challengeAttendees && challengeAttendees.length > 3 ?
          <ProfileImage chatHeader={1}>
            +{challengeAttendees && challengeAttendees.length - 3}
          </ProfileImage> : null
      }
    </div>
  };
  setObjIndex = (index, taskUpdated = 0, selectedDate = '') => {
    this.setState({ objIndex: index, taskUpdated: taskUpdated, selectedDate: selectedDate })
  }

  changeModal = () => {
    this.setState((prevState) => ({
      showVideo: !prevState.showVideo
    }));
  }

  joinChallenge = (challengeId, isJoined) => {
    const { joinOrLeaveChallenge } = this.props;
    const data = {
      challenge_id: challengeId,
      is_joined: isJoined
    };
    joinOrLeaveChallenge(data);
    this.setState({ isButtonDisabled: true });
    window.setTimeout(() => {
      this.setState({ isButtonDisabled: false });
    }, 2000);
  };

  render() {
    const { challenge, isLoading, comments, networkError, fetchChallengeCompetitors, location,
      postReplyOnComment, replySuccess, profileImage, userId, fetchTeamChallenge, role, updateDailyTask, syncTime, getUserOverviewDetails, getChallengeCsvData, challengeAttendees, userPermissions, user, t } = this.props;
    const { comment, objIndex, taskUpdated, selectedDate, imgSrc, showVideo, joinOrLeave, isButtonDisabled } = this.state;
    if ((_.isNull(challenge) || _.isUndefined(challenge) || Object.keys(challenge).length === 0 || _.isNull(challenge['challenge_type']) || _.isUndefined(challenge['challenge_type']) || _.isNull(profileImage) || _.isNull(userId) || _.isNull(challengeAttendees)) && !networkError) {
      return <Waiting />
    }
    const DistanceUnit = localStorage.getItem('DISTANCE');
    return (
      <React.Fragment>
        {(_.isNull(challenge) || _.isUndefined(challenge)) ? null :
          <Main>
            <LayoutNew display={(challenge.challenge_mode === 'Team' && challenge['challenge_type'] == 'survivor') ? "block" : "flex"}>
              <ChallengeGrid>
                <ChallengeInfoGridNew width={challenge['challenge_type'] == 'behavior' ? 1 : 0}>
                  {challenge && challenge.challenge_type !== 'behavior' && challenge.challenge_type !== 'new_group' && challenge.challenge_type !== 'group' && challenge.challenge_type !== 'survivor' ? <DetailedChallengeV2 role={role} userId={userId} isAdmin={role != 'USER'} challenge={challenge} navigateEdit={this.navigateEdit} syncTime={syncTime} userPermissions={userPermissions} /> : <BehaviorChallengeDetail challenge={challenge} />}
                  {
                    challenge && challenge.challenge_type === 'survivor' ?
                      <StyledTabsNew defaultActiveKey={1} id="styledTabs" onSelect={this.setActiveTab}>
                        <Tab eventKey={1} title={t("Overview")} tabClassName="first-tab">
                          {this.state.selectedTab === 1 ?
                            <SurvivorOverViewScreen
                              challenge={challenge}
                              userId={userId}
                              profileImage={profileImage}
                              getUserOverviewDetails={getUserOverviewDetails}
                              location={location}
                              fetchTeamChallenge={fetchTeamChallenge}
                              getChallengeCsvData={getChallengeCsvData}
                              role={role}
                              userPermissions={userPermissions}
                              history={this.props.history}
                            /> : null
                          }
                        </Tab>
                        <Tab eventKey={2} title={t("Leaderboard")} tabClassName="first-tab">
                          {this.state.selectedTab === 2 ?
                            <SurvivorLeaderboard
                              challenge={challenge}
                              fetchChallengeCompetitors={fetchChallengeCompetitors}
                              location={location}
                              networkError={networkError}
                              userId={userId}
                              profileImage={profileImage}
                              fetchTeamChallenge={fetchTeamChallenge}
                            /> : null
                          }
                        </Tab>
                        {challenge.challenge_status === 'joined' &&
                          <Tab eventKey={3} title={t("Chat")} tabClassName="third-tab">
                            {<FormContainer editChallenge={1} margin={1} marginTop={1}>
                              <h3>{t("Comments")}</h3>
                              <CommentContentContainer>
                                <FieldContainer>
                                  <CommentContent>
                                    <ImageWrapper isCommentSection borderRadius={"3px"}>
                                      {this.showProfileImage()}
                                    </ImageWrapper>
                                    <CommonTextArea
                                      placeholder={t("Write your comment here...")}
                                      rows={8}
                                      onChange={(e) => this.onChange(e)}
                                      value={comment}
                                      data-gramm_editor="false"
                                    />
                                    <IconContainerNew cameraIcon>
                                      <InputImage className="InputImageSection">
                                        {imgSrc && imgSrc.length >= 1 ? <img src={imgSrc} alt="uploadable" /> : ''}
                                        {imgSrc && imgSrc.length >= 1 ? <button onClick={() => this.removeImage()}><span className="InputImageSpan">X</span></button> : ''}
                                      </InputImage>
                                      <InputImageNew htmlFor="uploadPhoto" noMarginTop>
                                        <i className="fas fa-camera" />
                                        <input
                                          id="uploadPhoto"
                                          type="file"
                                          name="image"
                                          accept=".jpeg, .png, .jpg*"
                                          multiple={false}
                                          onChange={(e) => this.onChangeImage(e)}
                                          onClick={(e) => e.target.files[0] && this.onChangeImage(e)}
                                        />
                                      </InputImageNew>
                                    </IconContainerNew>
                                  </CommentContent>
                                  {(comment || imgSrc.length >= 1) ? <PostButton color="#159fc9" onClick={() => this.postComment(challenge.id)}>post</PostButton> : null}
                                </FieldContainer>
                                {(isLoading ? <Loading isHorizontal /> :
                                  (_.isEmpty(comments) ? <NoComments>{t("No comments to display")}</NoComments> :
                                    <BehaviorChallengeComments
                                      comments={comments}
                                      postReply={postReplyOnComment}
                                      id={challenge.id}
                                      replySuccess={replySuccess}
                                      history={this.props.history}
                                      isChallengeOrEvent={true}
                                    />
                                  )
                                )}
                              </CommentContentContainer>
                            </FormContainer>
                            }
                          </Tab>}
                      </StyledTabsNew> :
                      challenge.challenge_type === 'new_group' ?
                        <StyledTabsNew defaultActiveKey={1} id="styledTabs" onSelect={this.setActiveTab}>
                          <Tab eventKey={1} title={t("Overview")} tabClassName="first-tab">
                            {this.state.selectedTab === 1 ?
                              <GroupOverViewScreen
                                challenge={challenge}
                                userId={userId}
                                profileImage={profileImage}
                                getUserOverviewDetails={getUserOverviewDetails}
                                location={location}
                                fetchTeamChallenge={fetchTeamChallenge}
                                getChallengeCsvData={getChallengeCsvData}
                                role={role}
                                userPermissions={userPermissions}
                                history={this.props.history}
                              /> : null
                            }
                          </Tab>
                          <Tab eventKey={2} title={t("Leaderboard")} tabClassName="second-tab">
                            {this.state.selectedTab === 2 &&
                              <GroupChallengeLeaderboardV2
                                challenge={challenge}
                                location={location}
                                networkError={networkError}
                              />
                            }
                          </Tab>
                          {challenge.challenge_status === 'joined' &&
                            <Tab eventKey={3} title={t("Chat")} tabClassName="third-tab">
                              {<FormContainer editChallenge={1} margin={1} marginTop={1}>
                                <h3>{t("Comments")}</h3>
                                <CommentContentContainer>
                                  <FieldContainer>
                                    <CommentContent>
                                      <ImageWrapper isCommentSection borderRadius={"3px"}>
                                        {this.showProfileImage()}
                                      </ImageWrapper>
                                      <CommonTextArea
                                        placeholder={t("Write your comment here...")}
                                        rows={8}
                                        onChange={(e) => this.onChange(e)}
                                        value={comment}
                                        data-gramm_editor="false"
                                      />
                                      <IconContainerNew cameraIcon>
                                        <InputImage className="InputImageSection">
                                          {imgSrc && imgSrc.length >= 1 ? <img src={imgSrc} alt="uploadable" /> : ''}
                                          {imgSrc && imgSrc.length >= 1 ? <button onClick={() => this.removeImage()}><span className="InputImageSpan">X</span></button> : ''}
                                        </InputImage>
                                        <InputImageNew htmlFor="uploadPhoto" noMarginTop>
                                          <i className="fas fa-camera" />
                                          <input
                                            id="uploadPhoto"
                                            type="file"
                                            name="image"
                                            accept=".jpeg, .png, .jpg*"
                                            multiple={false}
                                            onChange={(e) => this.onChangeImage(e)}
                                            onClick={(e) => e.target.files[0] && this.onChangeImage(e)}
                                          />
                                        </InputImageNew>
                                      </IconContainerNew>
                                    </CommentContent>
                                    {(comment || imgSrc.length >= 1) ? <PostButton color="#159fc9" onClick={() => this.postComment(challenge.id)}>post</PostButton> : null}
                                  </FieldContainer>
                                  {(isLoading ? <Loading isHorizontal /> :
                                    (_.isEmpty(comments) ? <NoComments>{t("No comments to display")}</NoComments> :
                                      <BehaviorChallengeComments
                                        comments={comments}
                                        postReply={postReplyOnComment}
                                        id={challenge.id}
                                        replySuccess={replySuccess}
                                        history={this.props.history}
                                        isChallengeOrEvent={true}
                                      />
                                    )
                                  )}
                                </CommentContentContainer>
                              </FormContainer>
                              }
                            </Tab>}
                        </StyledTabsNew> :
                        challenge && challenge.challenge_type == 'group' ?
                          <StyledTabsNew defaultActiveKey={1} id="styledTabs" onSelect={this.setActiveTab}>
                            <Tab eventKey={1} title={t("Overview")} tabClassName="first-tab">
                              {this.state.selectedTab === 1 ?
                                <GroupOverViewScreen
                                  challenge={challenge}
                                  userId={userId}
                                  profileImage={profileImage}
                                  getUserOverviewDetails={getUserOverviewDetails}
                                  location={location}
                                  fetchTeamChallenge={fetchTeamChallenge}
                                  getChallengeCsvData={getChallengeCsvData}
                                  role={role}
                                  userPermissions={userPermissions}
                                  history={this.props.history}
                                /> : null
                              }
                            </Tab>
                            <Tab eventKey={2} title={t("Leaderboard")} tabClassName="second-tab">
                              {this.state.selectedTab === 2 &&
                                <ClassicChallengeLeaderboard
                                  challenge={challenge}
                                  fetchChallengeCompetitors={fetchChallengeCompetitors}
                                  location={location}
                                  networkError={networkError}
                                  userId={userId}
                                  profileImage={profileImage}
                                  fetchTeamChallenge={fetchTeamChallenge}
                                />
                              }
                            </Tab>
                            {challenge.challenge_status === 'joined' &&
                              <Tab eventKey={3} title={t("Chat")} tabClassName="third-tab">
                                {<FormContainer editChallenge={1} margin={1} marginTop={1}>
                                  <h3>{t("Comments")}</h3>
                                  <CommentContentContainer>
                                    <FieldContainer>
                                      <CommentContent>
                                        <ImageWrapper isCommentSection borderRadius={"3px"}>
                                          {this.showProfileImage()}
                                        </ImageWrapper>
                                        <CommonTextArea
                                          placeholder={t("Write your comment here...")}
                                          rows={8}
                                          onChange={(e) => this.onChange(e)}
                                          value={comment}
                                          data-gramm_editor="false"
                                        />
                                        <IconContainerNew cameraIcon>
                                          <InputImage className="InputImageSection">
                                            {imgSrc && imgSrc.length >= 1 ? <img src={imgSrc} alt="uploadable" /> : ''}
                                            {imgSrc && imgSrc.length >= 1 ? <button onClick={() => this.removeImage()}><span className="InputImageSpan">X</span></button> : ''}
                                          </InputImage>
                                          <InputImageNew htmlFor="uploadPhoto" noMarginTop>
                                            <i className="fas fa-camera" />
                                            <input
                                              id="uploadPhoto"
                                              type="file"
                                              name="image"
                                              accept=".jpeg, .png, .jpg*"
                                              multiple={false}
                                              onChange={(e) => this.onChangeImage(e)}
                                              onClick={(e) => e.target.files[0] && this.onChangeImage(e)}
                                            />
                                          </InputImageNew>
                                        </IconContainerNew>
                                      </CommentContent>
                                      {(comment || imgSrc.length >= 1) ? <PostButton color="#159fc9" onClick={() => this.postComment(challenge.id)}>post</PostButton> : null}
                                    </FieldContainer>
                                    {(isLoading ? <Loading isHorizontal /> :
                                      (_.isEmpty(comments) ? <NoComments>{t("No comments to display")}</NoComments> :
                                        <BehaviorChallengeComments
                                          comments={comments}
                                          postReply={postReplyOnComment}
                                          id={challenge.id}
                                          replySuccess={replySuccess}
                                          history={this.props.history}
                                          isChallengeOrEvent={true}
                                        />
                                      )
                                    )}
                                  </CommentContentContainer>
                                </FormContainer>
                                }
                              </Tab>}
                          </StyledTabsNew> :
                          challenge && challenge.challenge_type == 'behavior' ?
                            <StyledTabsNew defaultActiveKey={1} id="styledTabs">
                              <Tab eventKey={1} title={t("Overview")} tabClassName="first-tab">
                                {
                                  <MyTaskTabV2
                                    challenge={challenge}
                                    userId={userId}
                                    profileImage={profileImage}
                                    location={location}
                                    role={role}
                                    history={this.props.history}
                                    updateDailyTask={updateDailyTask}
                                    setObjIndex={this.setObjIndex}
                                    objIndex={objIndex}
                                    taskUpdated={taskUpdated}
                                    selectedDate={selectedDate}
                                    userPermissions={userPermissions}
                                  />
                                }
                              </Tab>
                              {
                                <Tab eventKey={2} title={t("Progress")} tabClassName="second-tab">
                                  {
                                    <MyProgressTab
                                      challenge={challenge}
                                      location={location}
                                      networkError={networkError}
                                      userId={userId}
                                      profileImage={profileImage}
                                    />
                                  }
                                </Tab>
                              }
                              {challenge && challenge['challenge_type'] == 'behavior' && challenge.challenge_status === 'joined' &&
                                <Tab eventKey={3} title={t("Chat")} tabClassName="third-tab">
                                  {<FormContainer editChallenge={1} margin={1} marginTop={1}>
                                    <h3>{t("Comments")}</h3>
                                    <CommentContentContainer>
                                      <FieldContainer>
                                        <CommentContent>
                                          <ImageWrapper isCommentSection borderRadius={"3px"}>
                                            {this.showProfileImage()}
                                          </ImageWrapper>
                                          <CommonTextArea
                                            placeholder={t("Write your comment here...")}
                                            rows={8}
                                            onChange={(e) => this.onChange(e)}
                                            value={comment}
                                            data-gramm_editor="false"
                                          />
                                          <IconContainerNew cameraIcon>
                                            <InputImage className="InputImageSection">
                                              {imgSrc && imgSrc.length >= 1 ? <img src={imgSrc} alt="uploadable" /> : ''}
                                              {imgSrc && imgSrc.length >= 1 ? <button onClick={() => this.removeImage()}><span className="InputImageSpan">X</span></button> : ''}
                                            </InputImage>
                                            <InputImageNew htmlFor="uploadPhoto" noMarginTop>
                                              <i className="fas fa-camera" />
                                              <input
                                                id="uploadPhoto"
                                                type="file"
                                                name="image"
                                                accept=".jpeg, .png, .jpg*"
                                                multiple={false}
                                                onChange={(e) => this.onChangeImage(e)}
                                                onClick={(e) => e.target.files[0] && this.onChangeImage(e)}
                                              />
                                            </InputImageNew>
                                          </IconContainerNew>
                                        </CommentContent>
                                        {(comment || imgSrc.length >= 1) ? <PostButton color="#159fc9" onClick={() => this.postComment(challenge.id)}>post</PostButton> : null}
                                      </FieldContainer>
                                      {(isLoading ? <Loading isHorizontal /> :
                                        (_.isEmpty(comments) ? <NoComments>{t("No comments to display")}</NoComments> :
                                          <BehaviorChallengeComments
                                            comments={comments}
                                            postReply={postReplyOnComment}
                                            id={challenge.id}
                                            replySuccess={replySuccess}
                                            history={this.props.history}
                                            isChallengeOrEvent={true}
                                          />
                                        )
                                      )}
                                    </CommentContentContainer>
                                  </FormContainer>
                                  }
                                </Tab>
                              }
                            </StyledTabsNew>
                            :
                            null
                  }
                </ChallengeInfoGridNew>
                {
                  challenge ? <RightContainer margin={challenge['challenge_type'] === 'behavior' || challenge['challenge_type'] === 'new_group' || challenge['challenge_type'] === 'group' || challenge['challenge_type'] === 'survivor'? "50px" : "0px"}>
                    {challenge && challenge['challenge_type'] == 'behavior' &&
                      <RightSideSection
                        challenge={challenge}
                        userId={userId}
                        profileImage={profileImage}
                        location={location}
                        role={role}
                        history={this.props.history}
                        updateDailyTask={updateDailyTask}
                        setObjIndex={this.setObjIndex}
                        objIndex={objIndex}
                        taskUpdated={taskUpdated}
                        selectedDate={selectedDate}
                        userPermissions={userPermissions}
                      />}
                    <CommentGridNew width={challenge && challenge['challenge_type'] == 'behavior' ? 1 : 0}>
                      {<div className="contents-top">
                        {challenge.challenge_type === 'behavior' && challenge.challenge_status !== 'over' ?
                          <ButtonContainer>
                            {challenge.is_joined === 1 ?
                              <EventsButton backgroundColor={"rgba(156,156,156,0.1)"} color={"#7F43FF"} padding={'15px 8px 15px 8px'} font={"18px"} cursor={"1"}>
                                {t("Challenge Joined")}
                              </EventsButton> :
                              <EventsButton backgroundColor={"#69C2FF"} color={"white"} onClick={isButtonDisabled?null:() => this.joinChallenge(challenge.id, joinOrLeave)} padding={'15px 8px 15px 8px'} font={"18px"}>
                                {t("Join The Challenge")}
                              </EventsButton>}
                            {!_.isNull(challenge.introduction_video) &&
                              <EventsButton backgroundColor={"linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%)"} onClick={this.changeModal} padding={'15px 8px 15px 8px'} font={"18px"}>
                                {t("Play Intro Video")}
                              </EventsButton>}
                          </ButtonContainer> : null}
                        {challenge && challenge.challenge_status === 'over' &&
                          <EventsButton backgroundColor={'rgba(156,156,156,0.1)'} color={"rgb(253,113,117)"} padding={'15px 8px 15px 8px'} font={"18px"} cursor={"1"}>
                            {t("Challenge Completed")}
                          </EventsButton>}
                        {challenge && challenge.challenge_status === 'over' && challenge['challenge_type'] !== 'behavior'&& (DistanceUnit === "KILOMETER"?<CompletedChallengeCard /* dangerouslySetInnerHTML={{ __html: challenge.metric_message }}/ */>{createSafeHTML(challenge.metric_message)}</CompletedChallengeCard> :<CompletedChallengeCard /* dangerouslySetInnerHTML={{ __html: challenge.message }}/ */>{createSafeHTML(challenge.message)}</CompletedChallengeCard>)}
                        {challenge && (challenge['challenge_type'] == 'behavior' || challenge.challenge_status === 'over') &&
                          <AboutContainer marginTop={'0px 0px 15px 0px'} color={"#0D4270"}>
                            <div>{t("About The Challenge")}</div>
                            <div >{challenge.body}</div>
                          </AboutContainer>}
                        {showVideo && !_.isNull(challenge.introduction_video) && !_.isNull(challenge.video_title) && <DemoPopup showModal={showVideo} onClose={this.changeModal} videoUrl={challenge.introduction_video} VideoTitle={challenge.video_title} />}
                        {challenge && challenge['challenge_type'] == 'behavior' || challenge['challenge_type'] === 'new_group' || challenge['challenge_type'] === 'group' || challenge['challenge_type'] === 'survivor' ? null :
                          <CommentContentContainer>
                            <ChatGroup width={challenge && challenge['challenge_type'] == 'behavior' ? "100%" : "65%"}>
                              <div>
                                <span>{t("Chat Group")}</span>
                                <div>{challenge.is_dummy_challenge === 1 ? '6320' : challengeAttendees.length} <span>{challengeAttendees.length > 1 ? 'Members' : 'Member'}</span></div>
                              </div>
                              <ChatUsers>
                                {this.getListOfUser()}
                              </ChatUsers>
                            </ChatGroup>
                            <FieldContainer commentsSection border={1}>
                              <ChatCommentContent>
                                <ChatTextArea
                                  placeholder={t("Write your comment here...")}
                                  rows={1}
                                  onChange={(e) => this.onChange(e)}
                                  value={comment}
                                  data-gramm_editor="false"
                                />
                                <div className="InputImageSection">
                                  {imgSrc && imgSrc.length >= 1 ? <img src={imgSrc} alt="uploadable" /> : ''}
                                  {imgSrc && imgSrc.length >= 1 ? <button onClick={() => this.removeImage()}>X</button> : ''}
                                </div>
                                <IconContainer cameraIcon>
                                  <InputImage htmlFor="uploadPhoto" noMarginTop>
                                    <i className="fas fa-camera" />
                                    <input
                                      id="uploadPhoto"
                                      type="file"
                                      name="image"
                                      accept=".jpeg, .png, .jpg*"
                                      multiple={false}
                                      onChange={(e) => this.onChangeImage(e)}
                                      onClick={(e) => e.target.files[0] && this.onChangeImage(e)}
                                    />
                                  </InputImage>
                                </IconContainer>
                                {comment || imgSrc.length >= 1 ? <span onClick={() => this.postComment(challenge.id)} disabled={!comment.trim()}>
                                  <img src={'/public/images/ClassicChallengeV2/send-button.png'} alt={'post'} />
                                </span> : ''}
                              </ChatCommentContent>
                            </FieldContainer>
                            {(isLoading ? <Loading isHorizontal /> :
                              (_.isEmpty(comments) ? <NoComments>{t("No comments to display")}</NoComments> :
                                <CommentsV2
                                  comments={comments}
                                  postReply={postReplyOnComment}
                                  id={challenge.id}
                                  replySuccess={replySuccess}
                                  history={this.props.history}
                                  isChallengeOrEvent={true}
                                />
                              )
                            )}
                          </CommentContentContainer>}
                        {challenge && (challenge['challenge_type'] === 'survivor') &&
                          <SurvivorRightSection
                            challenge={challenge}
                            userId={userId}
                            profileImage={profileImage}
                            getUserOverviewDetails={getUserOverviewDetails}
                            location={location}
                            networkError={networkError}
                            history={this.props.history}
                            role={role}
                          />}
                        {challenge && (challenge['challenge_type'] === 'new_group') &&
                          <GroupRightSection
                            challenge={challenge}
                            userId={userId}
                            profileImage={profileImage}
                            getUserOverviewDetails={getUserOverviewDetails}
                            location={location}
                            networkError={networkError}
                            history={this.props.history}
                            role={role}
                          />}
                        {challenge && (challenge['challenge_type'] === 'group') &&
                          <ClassicRightSection
                            challenge={challenge}
                            userId={userId}
                            profileImage={profileImage}
                            getUserOverviewDetails={getUserOverviewDetails}
                            location={location}
                            networkError={networkError}
                            history={this.props.history}
                            role={role}
                            fetchChallengeCompetitors={fetchChallengeCompetitors}
                          />}
                      </div>}
                    </CommentGridNew></RightContainer> : null
                }
              </ChallengeGrid>
              <StartChallengePopUp onClose={this.props.setChallengeInviteStatus} showModal={this.props.inviteAmigoStatus} userName={user['fname']} challenge={challenge} history={this.props.history} inviteAmigoStatus={1} />
            </LayoutNew>
          </Main>
        }
      </React.Fragment>
    )
  }
}

AdminCompletedChallengeDetails.propTypes = {
  location: PropTypes.object.isRequired,
  challenge: PropTypes.object,
  getChallengeDetails: PropTypes.func.isRequired,
  addCommentOnChallenge: PropTypes.func.isRequired,
  getCommentsOnChallenge: PropTypes.func.isRequired,
  comments: PropTypes.array,
  networkError: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  fetchChallengeCompetitors: PropTypes.func.isRequired,
  postReplyOnComment: PropTypes.func.isRequired,
  replySuccess: PropTypes.string,
  fetchUserProfileImage: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  fetchUserId: PropTypes.func.isRequired,
  userId: PropTypes.number,
  fetchTeamChallenge: PropTypes.func.isRequired,
  history: PropTypes.object,
  role: PropTypes.string,
  updateDailyTask: PropTypes.func,
  weekDashboard: PropTypes.number,
  changeWeekDashboard: PropTypes.func,
  match: PropTypes.object.isRequired,
  challengeAttendees: PropTypes.array,
  getUserListOfChallenges: PropTypes.func,
  getUserLastSync: PropTypes.func,
  syncTime: PropTypes.string,
  getUserOverviewDetails: PropTypes.func,
  getChallengeCsvData: PropTypes.func,
  emptyChallengeDetails: PropTypes.func,
  toggleDummyChallenges: PropTypes.func,
  userPermissions: PropTypes.array,
  inviteAmigoStatus: PropTypes.bool,
  setChallengeInviteStatus: PropTypes.func,
  user: PropTypes.object,
  joinOrLeaveChallenge: PropTypes.func,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  challenge: state.challenges.challengeDetails,
  comments: state.challenges.comments,
  isLoading: state.challenges.isLoading,
  networkError: state.challenges.networkError,
  replySuccess: state.challenges.replySuccess,
  profileImage: state.profileData.profileImage,
  userId: state.profileData.userId,
  role: state.auth.role,
  weekDashboard: state.challenges.weekDashboard,
  challengeAttendees: state.challenges.challengeAttendees,
  syncTime: state.challenges.syncTime,
  userPermissions: state.profileData.userPermissions,
  inviteAmigoStatus: state.challenges.inviteAmigoStatus,
  user: state.profileData.user
});

const mapDispatchToProps = (dispatch) => ({
  getChallengeDetails: (id) => dispatch(getChallengeDetails(id)),
  addCommentOnChallenge: (commentData) => dispatch(addCommentOnChallenge(commentData)),
  getCommentsOnChallenge: (id) => dispatch(getCommentsOnChallenge(id)),
  fetchChallengeCompetitors: (challengeId, challengeType, pageLimit, pageNumber, selectedOption, cb) => dispatch(getChallengeCompetitors(challengeId, challengeType, pageLimit, pageNumber, selectedOption, cb)),
  getUserOverviewDetails: (challengeId, challengeType, userId) => dispatch(getUserOverviewDetails(challengeId, challengeType, userId)),
  postReplyOnComment: (commentId, reply, challengeId) => dispatch(addCommentReplyOnChallenge(commentId, reply, challengeId)),
  fetchUserProfileImage: () => dispatch(getUserProfileImage()),
  fetchUserId: () => dispatch(getUserId()),
  fetchTeamChallenge: (challengeId, challengeType) => dispatch(getTeamChallenge(challengeId, challengeType)),
  updateDailyTask: (obj) => dispatch(updateDailyTask(obj)),
  changeWeekDashboard: (num) => dispatch(changeWeekDashboard(num)),
  getUserListOfChallenges: (challengeId) => dispatch(getUserListOfChallenges(challengeId)),
  getUserLastSync: () => dispatch(getUserLastSync()),
  getChallengeCsvData: (challengeId, challengeType, selectedOption) => dispatch(getChallengeCsvData(challengeId, challengeType, selectedOption)),
  emptyChallengeDetails: () => dispatch(emptyChallengeDetails()),
  toggleDummyChallenges: (bool) => dispatch(toggleDummyChallenges(bool)),
  setChallengeInviteStatus: (bool, challengeId) => dispatch(setChallengeInviteStatus(bool, challengeId)),
  joinOrLeaveChallenge: (data) => dispatch(joinOrLeaveChallenge(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminCompletedChallengeDetails)));
