/* eslint-disable no-console,no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  StyledModal, CalendarHeader, CalendarWrapper, ConfirmVisitText, ButtonContainer, ButtonV2
} from '../PeopleHomeV2/ManageBuddies/styles';
import { withTranslation } from 'react-i18next';

class LeavePopup extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { leaveId, showConfirmPopup, showHideLeavePopup, leaveData, userName, showDelete, deleteId,showConfirmPopupDelete,showHideDeletePopup,deleteData, showDeleteLibrary, t } = this.props;
    return (
      <StyledModal show={showDelete||showDeleteLibrary?showConfirmPopupDelete:showConfirmPopup} width={"400px"}>
        <CalendarHeader>
          <div>{showDelete||showDeleteLibrary?t("Delete The Challenge"):t("Leave The Challenge")}</div>
        </CalendarHeader>
        <CalendarWrapper padding="0 25px 25px 25px">
          {showDelete||showDeleteLibrary?
            <ConfirmVisitText>
              {t("Are you sure you want to Delete")} <br/>{t("this Challenge?")}
            </ConfirmVisitText>:
            <ConfirmVisitText>
              {t("Are you sure you want to Leave")} <br/>{t("this Challenge?")}
            </ConfirmVisitText>
          }
          {  
            <ConfirmVisitText>
              {showDeleteLibrary?
                <div className='note'>
                  {t("If you delete this challenge you will")}<br/>
                  {t("loose all the data of this challenge and")}<br/>
                  {t("you and other participants won’t get any")}<br/>
                  {t("points for the challenge.")}
                </div>:
                showDelete?
                  <div className='note'>
                    {t("If you delete this challenge you will")}<br/>
                    {t("loose all the data of this challenge and")}<br/>
                    {t("you and other participants won’t get any")}<br/>
                    {t("points for the challenge.")}
                  </div>:
                  <div className='note'>
                    {t("Hey")} {userName}, {t("If you leave this challenge")}<br/>
                    {t("in middle you won’t get the points")}<br/>
                    {t("for this challenge.")}
                  </div>}
            </ConfirmVisitText>
          }  
          <ButtonContainer>
            <ButtonV2
              background="#F4AAA9"
              onClick={showDelete||showDeleteLibrary?() => deleteData(deleteId): () => leaveData(leaveId)}
              width="100%"
              margin="0 0 10px 0"
              backgroundHover="rgb(253,113,117)"
              colorHover="white"
            >
              {showDelete||showDeleteLibrary?t("Yes, Delete it!"):t("Yes, I Want Leave")}
            </ButtonV2>
            <ButtonV2
              border="1"
              background="transparent"
              color="#005C8799"
              onClick={showDelete||showDeleteLibrary?() => showHideDeletePopup():() => showHideLeavePopup()}
              width="100%"
              backgroundHover="#005C87"
              colorHover="white"
            >
              {showDelete||showDeleteLibrary?t("Cancel"):t("No, I want to stay")}
            </ButtonV2>
          </ButtonContainer>
        </CalendarWrapper>
      </StyledModal>
    );
  }
}

LeavePopup.propTypes = {
  leaveId:PropTypes.number,
  showConfirmPopup: PropTypes.bool,
  showHideLeavePopup: PropTypes.func,
  leaveData: PropTypes.func,
  userName: PropTypes.func,
  deleteId:PropTypes.number,
  showConfirmPopupDelete:PropTypes.bool,
  showHideDeletePopup:PropTypes.func,
  deleteData:PropTypes.func,
  showDelete: PropTypes.func,
  showDeleteLibrary: PropTypes.bool,
  t: PropTypes.func
};

export default ( (withTranslation()) (LeavePopup));