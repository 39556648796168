/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EventDetailsContainer, ContainerFirst, } from '../EventDetails/styles';
import { VideoContainer, Container } from '../MainEvents/OnDemandVideoDetailsPage/styles';
import { ChallengeTopImage } from './styles'
import { /* getChallengeTimeToShow, */ convertDateInTimezone, getChallengeTimeToShowNew } from '../../utils/methods';
import momentTZ from 'moment-timezone';
import { ImageUrl } from '../../utils/constants';
import { withTranslation } from 'react-i18next';

class ChallengeDetailTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
    }
  }

  componentDidMount() {
    const { challenge, liveChallengeStatus } = this.props;
    this.interval = setInterval(() => {
      this.setState({
        time: liveChallengeStatus ? [] : getChallengeTimeToShowNew(challenge, this.props)
      })
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.challenge !== this.props.challenge) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.setState({
          time: this.props.liveChallengeStatus ? [] : getChallengeTimeToShowNew(this.props.challenge, this.props)
        })
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { challenge, liveChallengeStatus, t } = this.props;
    const { time } = this.state;
    const DaysLeft = liveChallengeStatus ? null : time.map((step) => step);

    return (
      <Container>
        <EventDetailsContainer>
          <ContainerFirst width={"100%"}>
            <VideoContainer height={"100%"} marginBottom="0">
              <ChallengeTopImage font={challenge.challenge_type==="new_group"?"24px": "20px"}>
                <div>{challenge.title}</div>
                <div>
                  <div className='challengeType'>
                    <div className='label'>
                      {challenge.challenge_type === 'survivor'?this.props.t("Survivor"):
                        challenge.challenge_type === 'group' && challenge.is_team_challenge !== 1?this.props.t("Classic"):
                          challenge.challenge_type === 'new_group'?this.props.t("Group"):
                            challenge.challenge_type === 'group' && challenge.is_team_challenge === 1?this.props.t("Team"):null} {this.props.t("Challenge")}
                    </div>
                  </div>
                  <div className="image"> <img src={`${ImageUrl}/${challenge.image}`} alt="back-arrow" />
                  </div>
                  {challenge.challenge_status === "over" ? null :
                    <div className="days">
                      <span>{(DaysLeft === 'Starts Today') ? t('Starts Today') : DaysLeft === 'Last Day'?t("Last Day") :(convertDateInTimezone(challenge.start_date)._d <= momentTZ()._d ? `${t("Remaining Days")} : ${DaysLeft}` : `${t("Days To Start")} : ${DaysLeft}`)}</span>
                    </div>}
                </div>
              </ChallengeTopImage>
            </VideoContainer>
          </ContainerFirst>
        </EventDetailsContainer>
      </Container>
    );
  }
}

ChallengeDetailTop.propTypes = {
  history: PropTypes.object,
  challenge: PropTypes.object.isRequired,
  updateDailyTask: PropTypes.func,
  liveChallengeStatus: PropTypes.bool,
  t: PropTypes.func
}

export default (withTranslation()(ChallengeDetailTop));